import * as R from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import Head from 'next/head'
import { useSnackbar } from 'notistack'
import { useRouter } from 'next/router'
import { Editor } from '@tinymce/tinymce-react'
// import { useQuery } from 'react-query'
import { useSession } from 'context/Session'
import { contentStyle } from 'components/layouts/Theme/tiny'
import { withSizes } from 'components/helpers'
import { Modal } from 'components/elements'
import { saveArticle, uploadArticleImage } from '../modules/datasource'
import SaveForm from './SaveForm'

import * as S from './Edit.styles'

const defaultProps = {}
const propTypes = {}

const isRumbleUrl = str => {
  return str && str.includes('rumble')
}

export const Edit = props => {
  const { isNew, isMobile, data } = props
  const { enqueueSnackbar } = useSnackbar()
  const [formData, setFormData] = useState(data || {})
  const [isModal, setModal] = useState(false)
  const router = useRouter()
  const { user, accessToken, verifyPermission } = useSession()
  const [isEditDisabled, setEditDisabled] = useState(false)
  const editorRef = useRef(null)
  const isUserComplete = user?.isProfileComplete & user?.isEmailConfirmed

  const { query } = router
  const { articleId, group } = query

  const asStaff =
    query?.staff || formData?.profile?.username === 'trialsitenews'

  useEffect(() => {
    if (!user) {
      router.push('/')
      return <div />
    }
    if (!isUserComplete) {
      router.push('/dashboard')
      return <div />
    }
    if (query?.staff && !verifyPermission(['Admin', 'Staff', 'SuperAdmin'])) {
      router.push('/write')
      return <div />
    }
  }, [user])

  const save = async () => {
    if (editorRef.current) {
      const { title = '' } = formData
      if (!title || R.length(title) < 20) {
        enqueueSnackbar('Title must be between 20 and 180', {
          variant: 'warning',
        })
        return
      }
      setEditDisabled(true)
      try {
        const { id } = await saveArticle({
          accessToken,
          values: {
            title: formData?.title,
            content: editorRef.current.getContent(),
            tags: null,
            links: null,
            documents: null,
            group,
          },
          articleId,
          isNew,
          asStaff,
        })
        router.push(`/drafts/${id}`)
        setEditDisabled(false)
        enqueueSnackbar('Draft saved', { variant: 'success' })
      } catch (e) {
        const message =
          e?.response?.data?.message ||
          e?.response?.data ||
          'Something went wrong'
        if (R.is(Array, message)) {
          enqueueSnackbar(message[0], { variant: 'error' })
        } else {
          enqueueSnackbar(message, { variant: 'error' })
        }
        setEditDisabled(false)
      }
    }
  }

  const publish = async () => {
    if (editorRef.current) {
      const { title = '' } = formData
      if (!title || R.length(title) < 20 || R.length(title) > 180) {
        enqueueSnackbar('Title must be between 20 and 180', {
          variant: 'warning',
        })
        return
      } else if (R.length(editorRef.current.getContent()) < 500) {
        enqueueSnackbar('Your article is too small to be published', {
          variant: 'warning',
        })
        return
      }
      setEditDisabled(true)
      setFormData(f => ({
        ...f,
        content: editorRef.current.getContent(),
        raw: editorRef.current.getContent({ format: 'raw' }),
      }))
      setModal(true)
      setEditDisabled(false)
    }
  }

  // const { data = {} } = useQuery(
  //   ['edit-article', articleId, accessToken, isNew],
  //   () => articleId && loadArticleDraft({ accessToken, articleId }),
  //   {
  //     fetchPolicy: 'no-cache',
  //     enabled: !R.isNil(articleId),
  //     onSuccess: setFormData,
  //     onError: () => router.push('/'),
  //   }
  // )

  const modalConfig = {
    articleId,
    groupId: group || data?.groupId,
    formData,
    setEditDisabled,
    save,
    isNew,
    asStaff,
  }

  return !isUserComplete ? null : (
    <S.Container>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <S.Content>
        <S.TitleInput
          onChange={e => setFormData(f => ({ ...f, title: e.target.value }))}
          value={formData?.title}
          placeholder="Article title"
          fullWidth
          disabled={isEditDisabled}
          key={`title-${data?.title}`}
          onKeyPress={e => e?.key === 'Enter' && e.preventDefault()}
          multiline={!isMobile}
          inputProps={{
            maxLength: 150,
          }}
        />
        <S.EditorWrapper>
          <S.FullDiv>
            <Editor
              key={`editor-${data?.title}`}
              disabled={isEditDisabled}
              id="tsnEditor"
              apiKey="7kjxi0errm734bpy9myad5wj9khfpxfj1hmxvlpgyxlbt4up"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={formData?.content}
              init={{
                // auto_focus: 'tsnEditor',
                menubar: false,
                statusbar: false,
                resize: false,
                toolbar_mode: 'wrap',
                skin: 'snow',
                icons: 'material',
                height: 'inherit',
                convert_urls: false,
                plugins: [
                  'advlist autolink lists link image',
                  'visualblocks fullscreen table',
                  'media paste wordcount quickbars imagetools',
                ],
                quickbars_selection_toolbar:
                  'bold italic | h2 h3 blockquote quickimage',
                quickbars_insert_toolbar: '',
                imagetools_toolbar: 'imageoptions',
                default_link_target: '_blank',
                target_list: false,
                browser_spellcheck: true,
                contextmenu: false,
                toolbar: [
                  {
                    name: 'history',
                    items: ['undo', 'redo'],
                  },
                  {
                    name: 'styles',
                    items: ['styleselect'],
                  },
                  {
                    name: 'formatting',
                    items: ['bold', 'italic', 'underline', 'link'],
                  },
                  {
                    name: 'alignment',
                    items: [
                      'alignleft',
                      'aligncenter',
                      'alignright',
                      'alignjustify',
                    ],
                  },
                  {
                    name: 'list',
                    items: ['bullist', 'numlist'],
                  },
                  {
                    name: 'content',
                    items: ['quickimage', 'media', 'table'],
                  },
                ],
                video_template_callback: function (data) {
                  if (isRumbleUrl(data?.source)) {
                    return `
                      <iframe src="${data?.source}"></iframe>
                    `
                  }
                  return (
                    '<video width="' +
                    data.width +
                    '" height="' +
                    data.height +
                    '"' +
                    (data.poster ? ' poster="' + data.poster + '"' : '') +
                    ' controls="controls">\n' +
                    '<source src="' +
                    data.source +
                    '"' +
                    (data.sourcemime ? ' type="' + data.sourcemime + '"' : '') +
                    ' />\n' +
                    (data.altsource
                      ? '<source src="' +
                        data.altsource +
                        '"' +
                        (data.altsourcemime
                          ? ' type="' + data.altsourcemime + '"'
                          : '') +
                        ' />\n'
                      : '') +
                    '</video>'
                  )
                },
                content_style: contentStyle,
                selector: 'textarea',
                placeholder: 'Type to start your article',
                mobile: {
                  toolbar_mode: 'wrap',
                },
                style_formats: [
                  { title: 'Normal text', format: 'p' },
                  { title: 'Heading 1', format: 'h1' },
                  { title: 'Heading 2', format: 'h2' },
                  { title: 'Heading 3', format: 'h3' },
                  { title: 'Heading 4', format: 'h4' },
                ],
                images_upload_handler: async function (
                  blobInfo,
                  success,
                  failure
                ) {
                  try {
                    const file = blobInfo?.blob()
                    const imgSrc = await uploadArticleImage({
                      file,
                      accessToken,
                    })
                    success(imgSrc)
                  } catch {
                    failure('Something went wrong')
                  }
                },
                init_instance_callback: function (editor) {
                  editor.on('BeforeSetContent', function (e) {
                    if (e.content && e.content.includes('blob:')) {
                      const s = e.content
                        .substr(e.content.indexOf('blob'), e.content.length)
                        .replace('/>', '')
                        .replace('>', '')
                        .replace('"', '')
                        .trim()
                      if (e.target.editorUpload.blobCache.getByUri(s)) {
                        const file = e.target.editorUpload.blobCache
                          .getByUri(s)
                          .blob()
                        const size = file?.size / 1024

                        const maxSize = 4096
                        if (size > maxSize) {
                          enqueueSnackbar(
                            `Files should be equal or lower than 4mb`,
                            {
                              variant: 'error',
                            }
                          )
                          e.preventDefault()
                          e.stopPropagation()
                        }
                      }
                    }
                  })
                },
                // file_picker_callback: async function (cb, v, m) {
                //   console.log('>>>>>>v', { v, m })
                //   var input = document.createElement('input')
                //   input.setAttribute('type', 'file')
                //   input.setAttribute('accept', 'image/*')

                //   input.onchange = async function () {
                //     var file = this.files[0]

                //     console.log('>>>>>>file', file)

                //     try {
                //       const imgSrc = await uploadArticleImage({
                //         file,
                //         accessToken,
                //       })
                //       cb(imgSrc, { title: file.name })
                //     } catch {
                //       enqueueSnackbar('Something went wrong', {
                //         variant: 'error',
                //       })
                //     }
                //   }

                //   input.click()
                // },
                file_picker_types: 'image',
                image_title: true,
              }}
            />
          </S.FullDiv>
        </S.EditorWrapper>
      </S.Content>
      <S.Footer>
        <S.FContent>
          {asStaff && (
            <S.StaffWrapper>
              <S.StaffTag>TrialSite Staff</S.StaffTag>
            </S.StaffWrapper>
          )}
          <S.FooterButton variant="text" onClick={save}>
            {formData?.publishedAt ? 'Save' : 'Save as draft'}
          </S.FooterButton>
          <S.FooterButton onClick={publish}>Publish</S.FooterButton>
          <S.FooterButton
            variant="text"
            onClick={() =>
              router.push(articleId ? `/articles/${articleId}` : '/dashboard')
            }
          >
            Close
          </S.FooterButton>
        </S.FContent>
      </S.Footer>
      <Modal
        isOpen={isModal}
        close={() => setModal(false)}
        closeOnOutside={false}
      >
        <SaveForm {...modalConfig} />
      </Modal>
    </S.Container>
  )
}

Edit.defaultProps = defaultProps
Edit.propTypes = propTypes

export default withSizes(Edit)
