import api from 'shared/api'

export const reportArticle = ({ values, articleSlug, accessToken }) => {
  return api.post(
    '/reports',
    {
      ...values,
      articleSlug,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

const priceIDMap = {
  personal: process.env.NEXT_PUBLIC_PERSONAL_ID,
  professional: process.env.NEXT_PUBLIC_PROFESSIONAL_ID,
}

export const doBill = async ({ type, accessToken, returnUrl }) => {
  const { data } = await api.post(
    '/billing/checkout',
    {
      priceId: priceIDMap[type],
      returnUrl,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )

  return { ...data }
}

export const loadComments = async ({
  reference,
  accessToken,
  page = 1,
  limit = 10,
  parentId,
}) => {
  const {
    data: list,
    headers: { 'x-total-results': total },
  } = await api.get('/comments', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      type: 'Article',
      reference,
      startFromId: parentId,
      page,
      limit,
    },
  })

  return { list, total }
}

export const publishComment = async ({
  text,
  reference,
  parentId,
  accessToken,
}) => {
  return api.post(
    '/comments',
    {
      text,
      type: 'Article',
      reference,
      parentId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const editComment = async ({
  text,
  id,

  accessToken,
}) => {
  return api.put(
    `/comments/${id}`,
    {
      text,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const deleteComment = async ({ accessToken, id }) => {
  await api.delete(`/comments/${id}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const loadIsLiked = async ({ articleId, accessToken }) => {
  const { data: isLiked } = await api.get(`/articles/liked/${articleId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  return isLiked
}

export const like = async ({ accessToken, articleId }) => {
  await api.post(`/articles/like/${articleId}`, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const unlike = async ({ accessToken, articleId }) => {
  await api.delete(`/articles/like/${articleId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}
