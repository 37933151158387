import * as R from 'ramda'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'
import { MdCheck } from 'react-icons/md'
import { useSession } from 'context/Session'
import { Button, Modal } from 'components/elements'
import AuthSteps from 'components/collections/AuthSteps'

import { doBill } from '../modules/datasource'
import { jona64, peter64 } from '../modules/constants'

const defaultProps = {}
const propTypes = {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  ${({ showOverlay }) =>
    showOverlay &&
    css`
      margin-top: -100px;
      z-index: 1;
    `}
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 64px;
`
const Title = styled.div`
  ${({ theme }) => theme.font.h2};
`
const SubTitle = styled.div`
  ${({ theme }) => theme.font.h3};
  font-weight: normal;

  ${({ theme }) => theme.media.phone} {
    ${({ theme }) => theme.font.body};
    br {
      display: none;
    }
  }
`
const Desc = styled.div`
  margin: 16px auto 48px;
  max-width: 750px;
`
const OptionsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  max-width: 1440px;
  margin: 0 auto;
  ${({ theme }) => theme.media.phone} {
    grid-template-columns: 1fr;
  }
`
const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: solid 1px ${({ theme }) => theme.colors.grey};
  padding: 48px 59px 64px;
  min-height: 480px;
  max-width: 400px;
  ${({ theme }) => theme.media.tablet} {
    padding: 48px 29px 64px;
    height: unset;
  }
  ${({ theme }) => theme.media.phone} {
    height: 480px;
  }
`
const OptionTitle = styled.div`
  font-family: Amiri;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 26px;
  border-bottom: solid 2px
    ${({ theme, color }) => theme.colors[color] || theme.colors.black};
`
const OptionPrice = styled.div`
  ${({ theme }) => theme.font.h2};
  margin-bottom: 8px;
  ${({ theme }) => theme.media.tablet} {
    ${({ theme }) => theme.font.h3};
  }
`
const OptionPriceDesc = styled.div`
  ${({ theme }) => theme.font.body2};
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: 24px;
`
const OptionPoints = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
`
const PointContainer = styled.div`
  display: grid;
  grid-template-columns: 13px 1fr;
  grid-gap: 10px;
  text-align: left;
  & + & {
    margin-top: 8px;
  }
`
const PointCheck = styled(MdCheck)``
const PointLabel = styled.div`
  ${({ theme }) => theme.font.body2};
  color: ${({ theme }) => theme.colors.grey};
`
const Point = ({ label }) => {
  return (
    <PointContainer>
      <PointCheck />
      <PointLabel>{label}</PointLabel>
    </PointContainer>
  )
}
const SubscribeButton = styled(Button)`
  background: ${({ theme, colorVariant }) =>
    theme.colors[colorVariant] || theme.colors.black};
  margin-top: 20px;
  min-width: 150px;
  ${({ theme }) => theme.media.tablet} {
    min-width: unset;
  }
`
const PaywallOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  ${SubscribeButton} + ${SubscribeButton} {
    margin-left: 10px;
  }
  ${({ theme }) => theme.media.phone} {
    margin: 10px 0;
    flex-direction: column;

    ${SubscribeButton} + ${SubscribeButton} {
      margin: 10px 0 0 0;
    }
  }
`

const ReviewList = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  ${({ showOptions }) =>
    !showOptions &&
    css`
      margin-top: 140px;
      padding-top: 40px;
      border-top: solid 1px ${({ theme }) => theme.colors.grey};
    `}
`

const StyledReview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 750px;
  & + & {
    margin-top: 45px;
  }
`
const Author = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
`
const AuthorImg = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryL};
  border-radius: 50%;
  height: 48px;
  width: 48px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`
const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
`
const AuthorName = styled.div``
const ReviewDesc = styled.div`
  ${({ theme }) => theme.font.h3};
  text-align: center;
`
const Review = ({ profileImage, firstName, lastName, review }) => {
  return (
    <StyledReview>
      <Author>
        <AuthorImg>
          {profileImage ? <StyledImg src={profileImage} /> : R.head(firstName)}
        </AuthorImg>
        <AuthorName>{`${firstName}${
          lastName ? ` ${lastName}` : ''
        }`}</AuthorName>
      </Author>
      <ReviewDesc>&quot;{review}&quot;</ReviewDesc>
    </StyledReview>
  )
}

const Overlay = styled.div`
  height: 96px;
  background: linear-gradient(
    180deg,
    rgba(204, 229, 255, 0) 0%,
    rgba(255, 255, 255, 0) 0.01%,
    #ffffff 100%
  );
`

export const PayWall = ({ showOverlay, returnUrl }) => {
  const router = useRouter()
  const [hasSentConfirm, setHasSentConfirm] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [isFetchingPersonal, setIsFetchingPersonal] = useState(false)
  const [isFetchingProfessional, setIsFetchingProfessional] = useState(false)
  const { user, accessToken, resendConfirmationEmail } = useSession()
  const [showOptions, setShowOptions] = useState(false)

  const subFress = () => {
    if (accessToken) {
      if (!hasSentConfirm) {
        resendConfirmationEmail().then(() => {
          setHasSentConfirm(true)
        })
      }
    } else {
      setOpen()
    }
  }

  const subPersonal = async () => {
    if (accessToken) {
      setIsFetchingPersonal(true)
      const { checkoutUrl } = await doBill({
        type: 'personal',
        accessToken,
        returnUrl,
      })
      router.push(checkoutUrl)
      // setIsFetchingPersonal(false)
    } else {
      setOpen()
    }
  }

  const subProfessional = async () => {
    if (accessToken) {
      setIsFetchingProfessional(true)
      const { checkoutUrl } = await doBill({
        type: 'professional',
        accessToken,
        returnUrl,
      })
      router.push(checkoutUrl)
      // setIsFetchingProfessional(false)
    } else {
      setOpen()
    }
  }

  const needConfirmEmail = user && !user?.isEmailConfirmed
  const disableFreeOption = user && user?.isEmailConfirmed

  return (
    <Container showOverlay={showOverlay}>
      {showOverlay && <Overlay />}
      {!showOptions && (
        <Header>
          <Title style={{ fontWeight: 'normal' }}>
            Quality journalism costs money to produce.
          </Title>
          <PaywallOptions>
            {!accessToken && (
              <SubscribeButton colorVariant="black" onClick={subFress}>
                Login for free
              </SubscribeButton>
            )}
            <SubscribeButton
              colorVariant="primary"
              onClick={() => setShowOptions(true)}
            >
              View subscription options
            </SubscribeButton>
          </PaywallOptions>
          <SubTitle>
            <p>Free access provides up to 10 articles</p>
            <p>
              Subscription options start at $5 per month&nbsp;
              <br />
              which is less than a Starbucks coffee!
            </p>
          </SubTitle>
        </Header>
      )}
      {showOptions && (
        <Header>
          <Title>Quality journalism costs money to produce.</Title>
          <Desc>
            {`We will be reinvesting the proceeds of these subscriptions into an
          increasing volume of high quality, independent, unbiased reporting and
          expert analysis. Our subscription tiers use the ‘honor system’ –
          please do not abuse it. All tiers of service provide the same level of
          access to content. We are grateful for all of you, and we hope you
          reciprocate.`}
          </Desc>
        </Header>
      )}
      {showOptions && (
        <OptionsContainer>
          <Option>
            <OptionTitle color="grey">Basic</OptionTitle>
            <OptionPrice>Free</OptionPrice>
            <OptionPriceDesc>Simply login to read for free</OptionPriceDesc>
            <OptionPoints>
              <Point label="Up to 10 free articles" />
              <Point label="Easy upgrade to a paid tier" />
            </OptionPoints>
            <SubscribeButton
              colorVariant="grey"
              onClick={subFress}
              disabled={
                isFetchingPersonal ||
                isFetchingProfessional ||
                disableFreeOption
              }
              loading={isFetchingPersonal}
            >
              {accessToken
                ? needConfirmEmail
                  ? 'Confirm your email'
                  : 'Selected'
                : 'Get Started'}
            </SubscribeButton>
          </Option>
          <Option>
            <OptionTitle color="primary">Personal</OptionTitle>
            <OptionPrice>$5 per month</OptionPrice>
            <OptionPriceDesc>
              Billed $50 annually or <br />
              $5 month-to-month
            </OptionPriceDesc>
            <OptionPoints>
              <Point label="Like a Starbucks a month" />
              <Point label="No automatic renew with annual billing" />
              <Point label="Individuals at home that are reading our content for personal health reasons" />
            </OptionPoints>
            <SubscribeButton
              colorVariant="primary"
              onClick={subPersonal}
              disabled={isFetchingPersonal || isFetchingProfessional}
              loading={isFetchingPersonal}
            >
              {accessToken ? 'Subscribe Personal' : 'Get Started'}
            </SubscribeButton>
          </Option>
          <Option>
            <OptionTitle>Professional</OptionTitle>
            <OptionPrice>$12 per month</OptionPrice>
            <OptionPriceDesc>Billed $12 month-to-month</OptionPriceDesc>
            <OptionPoints>
              <Point label="Way better than coffee" />
              <Point label="Professionals from the healthcare industry subscribing on behalf of their company for work reasons." />
            </OptionPoints>
            <SubscribeButton
              disabled={isFetchingPersonal || isFetchingProfessional}
              loading={isFetchingProfessional}
              onClick={subProfessional}
            >
              {accessToken ? 'Subscribe Professional' : 'Get Started'}
            </SubscribeButton>
          </Option>
        </OptionsContainer>
      )}
      <ReviewList showOptions={showOptions}>
        <Review
          profileImage={peter64}
          firstName="Peter"
          lastName="Gabriel, MD"
          review="Publishing on TrialSite helped me secure a $20m research grant for my work."
        />
        <Review
          profileImage={jona64}
          firstName="Jona"
          lastName="Savinda"
          review="TrialSiteNews is my daily read for what is happening in the industry."
        />
        <Review
          profileImage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9ncYzq292dKV3doALMttfpnhcQeHbL8hRtoFz0oPTWj2D-FG85DhZ_IBt_lrOyPQJpv4&usqp=CAU"
          firstName="Lucas"
          lastName="Van Der Hoven"
          review="Objective, unbiased reporting on the latest news in medical research. I trust TrialSiteNews to give it to me straight."
        />
      </ReviewList>

      <Modal
        isOpen={isOpen}
        close={() => setOpen(false)}
        closeOnOutside={false}
      >
        <AuthSteps />
      </Modal>
    </Container>
  )
}

PayWall.defaultProps = defaultProps
PayWall.propTypes = propTypes

export default PayWall
