import { media } from './media'

export const contentStyle = `
  @import url('https://fonts.googleapis.com/css2?family=Amiri&display=swap');
  ::selection {
    background-color: #007CFF;
    color: #fff;
  }
  body {
    font-family: 'Amiri', serif;
    font-size: 20px;
    line-height: 30px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.5px;
  }
  h1 {
    line-height: 40px;
  }
  ${media.phone} {
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }
  img {
    max-width: 100%;
  }

`

export const viewContentStyle = `
  ${contentStyle}
`
