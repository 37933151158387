import * as R from 'ramda'
import React, { useState, useMemo, useEffect } from 'react'
// import Script from 'next/script'
import Link from 'next/link'
import { useRouter } from 'next/router'
import parse from 'html-react-parser'
import Skeleton from '@mui/material/Skeleton'
import Fade from '@mui/material/Fade'
import { useSession } from 'context/Session'
import { useLayout } from 'context/Layout'
import { ShareBar } from 'components/elements'
import { withSizes } from 'components/helpers'
import { getCloudFlareImgVariant } from 'components/helpers/withSizes'
import PayWall from './PayWall'
import Schema from './ArticleSchema'
import Comments from './Comments'
import LikeButton from './LikeButton'

import api from 'shared/api'

import * as S from './View.styles'

const defaultProps = {}
const propTypes = {}

export const View = ({
  articleId,
  asDraft,
  username,
  articleSlug,
  host = 'trialsitenews.com',
  data,
  // contentData,
  commentsData,
  user,
  isMobile,
  isLiked,
}) => {
  const { setLayoutConfig } = useLayout()

  const router = useRouter()
  const [isFetching, setIsFetching] = useState(true)
  const [reportOpen, setReportOpen] = useState(false)
  const [showComments, setShowComments] = useState(true)
  const { user: sessionUser, verifyPermission } = useSession()
  const { profile } = sessionUser || {}
  const [contentData, setContentData] = useState(null)

  useEffect(() => {
    if (asDraft) {
      setContentData(data)
      setIsFetching(false)
      return
    }

    setIsFetching(true)
    api
      .get(`/public/articles/${articleSlug}/content`, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      })
      .then(async ({ data }) => {
        setIsFetching(false)
        setContentData(data)
      })
      .catch(function () {
        setIsFetching(false)
        setContentData(null)
      })
  }, [])

  const shareLink = useMemo(
    () => `https://${host}/a/${articleSlug}`,
    [host, articleSlug]
  )

  const canEdit = useMemo(() => {
    return (
      articleId ||
      profile?.username === data?.profile?.username ||
      (username === 'trialsitenews' &&
        verifyPermission(['Admin', 'Staff', 'SuperAdmin']))
    )
  }, [articleId, profile, username, data])

  const hasRestriction = useMemo(() => {
    const articleIsMine =
      user && data?.profile?.username === sessionUser?.profile?.username

    if (articleIsMine || articleId || user?.isMember || canEdit) {
      return false
    }

    return (
      !asDraft &&
      user &&
      (!R.isNil(contentData?.viewsLeft) || !contentData?.content)
    )
  }, [user, sessionUser, data, contentData, asDraft, articleId, canEdit])

  const commentsConfig = {
    showComments,
    setShowComments,
  }

  useEffect(() => {
    if (user && user?.isEmailConfirmed && hasRestriction) {
      setLayoutConfig(c => ({
        ...c,
        header: {
          Stripe: () =>
            !isFetching && (
              <S.ViewCountInfo id="view-count" count={contentData?.viewsLeft} />
            ),
        },
      }))
    }

    return () => setLayoutConfig(c => ({ ...c, header: {} }))
  }, [user, hasRestriction, contentData, isFetching])

  const commentDesc = useMemo(() => {
    if (isMobile) {
      return data?.comments > 0 ? data?.comments : ''
    }
    return `${data?.comments || 0} comment${data?.comments > 1 ? 's' : ''}`
  }, [data?.comments, isMobile])

  return (
    <>
      <S.Container>
        <Schema data={data} asDraft={asDraft} />
        <S.Content>
          {asDraft && <S.DraftTag>Draft</S.DraftTag>}
          <S.Title>{data?.title}</S.Title>
          <S.ShareWrapper>
            <ShareBar
              disableMenu={!user}
              disableShare={asDraft}
              id={articleId}
              url={shareLink}
              quote={data?.title}
              onEdit={
                canEdit ? () => router.push(`/articles/${data?.id}/edit`) : null
              }
              actions={
                asDraft || !sessionUser
                  ? []
                  : [
                      {
                        label: 'Report',
                        onClick: () => setReportOpen(true),
                      },
                    ]
              }
            />
            <S.OptionsContent>
              <LikeButton
                style={{ marginRight: 15 }}
                data={data}
                user={user}
                isLiked={isLiked}
              />
              {user ? (
                <Link href="#comments" passHref>
                  <S.CommentsCount>
                    <S.CommentIcon />
                    {commentDesc}
                  </S.CommentsCount>
                </Link>
              ) : (
                <S.CommentsCount disabled>
                  <S.CommentIcon />
                  {commentDesc}
                </S.CommentsCount>
              )}
            </S.OptionsContent>
          </S.ShareWrapper>
          {data?.image && (
            <S.ArticleImg
              src={getCloudFlareImgVariant(data?.image, {
                default: 'articleImageDefault',
              })}
            />
          )}
          {data?.profile && <S.Author {...data} />}
          {isFetching ? (
            <S.PaidContent style={{ paddingBottom: 30 }}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton width="60%" />
              <br />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton width="60%" />
            </S.PaidContent>
          ) : (
            <Fade in={!isFetching} timeout={400}>
              <S.PaidContent class="paywall">
                {contentData?.content && (
                  <S.ArticleContent>
                    {parse(contentData?.content)}
                  </S.ArticleContent>
                )}
                {!R.isEmpty(contentData?.references || []) && (
                  <S.ReferenceTitle>References</S.ReferenceTitle>
                )}
                {!R.isEmpty(contentData?.references || []) && (
                  <S.RefList>
                    {contentData?.references.map(ref => (
                      <S.ListItem key={ref.title}>
                        {ref?.type === 'Link' ? <S.LinkIcon /> : <S.PdfIcon />}
                        <S.ItemDesc
                          href={ref?.value}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {ref?.title || ref?.value}
                        </S.ItemDesc>
                      </S.ListItem>
                    ))}
                  </S.RefList>
                )}
                {!(!user || hasRestriction) && !R.isEmpty(data?.tags || []) && (
                  <S.TagsContainer>
                    {data?.tags.map(tag => (
                      <Link
                        href={`/topics/${tag?.slug}`}
                        passHref
                        key={`tag-${tag.name}`}
                      >
                        <a>
                          <S.TagItem label={tag.name} />
                        </a>
                      </Link>
                    ))}
                  </S.TagsContainer>
                )}
              </S.PaidContent>
            </Fade>
          )}
        </S.Content>
        {!isFetching && (!user || hasRestriction) && (
          <>
            {data?.excerpt && !contentData?.content && (
              <S.Content>
                <S.ArticleContent>{parse(data?.excerpt)}</S.ArticleContent>
              </S.Content>
            )}
            <PayWall
              showOverlay={!contentData?.content}
              returnUrl={shareLink}
            />
          </>
        )}
        <S.ReportModal isOpen={reportOpen} close={() => setReportOpen(false)}>
          <S.ReportForm articleSlug={articleSlug} />
        </S.ReportModal>
      </S.Container>
      {!asDraft && user && contentData?.content && (
        <div
          id="comments"
          style={{
            minHeight: 'unset',
            scrollMarginTop: '73px',
          }}
        >
          <Comments
            reference={data?.id}
            count={data?.comments}
            initialState={commentsData}
            {...commentsConfig}
          />
        </div>
      )}
    </>
  )
}

View.defaultProps = defaultProps
View.propTypes = propTypes

export default withSizes(View)
