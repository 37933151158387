import styled, { css } from 'styled-components'
import { Button, Input, Tag } from 'components/elements'
import { getAlphaRgb } from 'components/layouts'
import { BiLink } from 'react-icons/bi'
import { MdPictureAsPdf } from 'react-icons/md'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 15px;
`

export const Content = styled.div`
  width: 100%;
  display: grid;
  padding: 0 0 44px;
  width: 100%;
  max-width: 704px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.phone} {
    padding-top: 32px;
  }
`

const sectionsBorder = css`
  border-top: solid 1px ${({ theme }) => theme.colors.grey};
`
export const ActionsSection = styled.div`
  margin-top: 8px;
  /* margin-top: 32px; */
  padding-top: 32px;
  ${sectionsBorder}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => theme.media.phone} {
    justify-content: center;
  }
`
export const ArticleSection = styled.div`
  width: 100%;
  display: flex;
  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
    align-items: center;
  }
`
export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  div {
    ${({ theme }) => theme.font.label}
  }
`
export const TagsSection = styled.div`
  margin-top: 32px;
  padding-top: 24px;
  ${sectionsBorder}
`
export const DocumentsSection = styled.div`
  margin-top: 32px;
  padding-top: 8px;
  ${sectionsBorder}
`

export const ImgPreview = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 234px;
  width: 100%;
  max-width: 416px;
  background: ${({ theme }) => theme.colors.lightGrey};
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  margin-bottom: 8px;
  ${({ src }) =>
    src &&
    css`
      background-image: ${`url(${src})`};
      background-size: cover;
      background-position: center;
      &:hover {
        &::after {
          content: 'Edit';
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: ${({ theme }) => getAlphaRgb(theme.colors.grey, 0.5)};
          color: ${({ theme }) => theme.colors.white};
        }
      }
    `};

  ${({ theme }) => theme.media.phone} {
    width: 100%;
    height: 193px;
    max-width: unset;
  }
`
export const ArticlePreview = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 234px;
  width: 272px;
  min-width: 272px;
  max-width: 272px;
  margin-left: 16px;
  ${({ theme }) => theme.media.phone} {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
    height: unset;
    min-width: unset;
    max-width: unset;
  }
`
export const TitlePreview = styled.div`
  ${({ theme }) => theme.font.h3}
`
export const ContentPreview = styled.div`
  flex: 1;
  overflow: hidden;
`

export const StepButton = styled(Button)`
  grid-column: 1 / 3;
  & + & {
    margin-left: 15px;
  }
`

export const TagsTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`

export const TagsContent = styled.div`
  display: flex;
  .MuiAutocomplete-root {
    width: 200px;
  }
  .MuiOutlinedInput-root {
    padding: 7px;
  }

  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 18px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`

export const TagInput = styled(Input)``

export const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: -4px 16px;
  ${({ theme }) => theme.media.phone} {
    margin: 16px -4px 0;
  }
`

export const TagItem = styled(Tag)`
  margin: 4px;
`
export const TagLabel = styled.div`
  display: flex;
  align-items: center;
`
export const LinkIcon = styled(BiLink)`
  font-size: 18px;
  margin-right: 10px;
`
export const PdfIcon = styled(MdPictureAsPdf)`
  font-size: 18px;
  margin-right: 10px;
`

export const ReferencesContent = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(100px, 344px) 1fr;
  grid-gap: 26px 16px;
  ${({ theme }) => theme.media.phone} {
    grid-template-columns: 1fr;
  }
`

export const ReferencesTitleInput = styled(Input)`
  &.MuiTextField-root {
    width: 200px;
    height: 48px;
  }
  .MuiOutlinedInput-input {
    padding: 14.5px 14px;
  }
  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 18px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`
export const ReferenceLinkInput = styled(ReferencesTitleInput)`
  &.MuiTextField-root {
    width: 100%;
  }
`

export const ReferenceAddButton = styled(Button)`
  height: 48px;
  width: 56px;
`

export const RefList = styled.div`
  grid-column: 1 / 4;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: -4px;
  ${({ theme }) => theme.media.phone} {
    grid-column: 1;
  }
`

export const DocumentsContent = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`

export const DocumentsAddButton = styled(Button)`
  height: 56px;
  width: 181px;
  padding: 0;
`

export const DocumentsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: -4px;
  margin-left: 32px;
  ${({ theme }) => theme.media.phone} {
    margin-left: -4px;
    margin-top: 16px;
  }
`

export const StaffTag = styled.div`
  background: ${({ theme }) => theme.colors.primaryL};
  color: ${({ theme }) => theme.colors.primary};
  padding: 5px 8px;
  border-radius: 8px;
  width: fit-content;
`
