import styled, { css } from 'styled-components'
import { Button, Input } from 'components/elements'

const contentPadding = css`
  padding: 0 152px;
  ${({ theme }) => theme.media.tablet} {
    padding: 0 32px;
  }
  ${({ theme }) => theme.media.phone} {
    padding: 0;
  }
`

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  flex: 1;
  align-items: center;
  overflow: hidden;
  /* iframe {
    border: 0 !important;
    border-top: solid 1px ${({ theme }) => theme.colors.grey2} !important;
  } */
`

export const Footer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 73px;
  width: 100%;
  border-top: solid 1px ${({ theme }) => theme.colors.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.media.phone} {
    height: 110px;
  }
`
export const FContent = styled.div`
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${contentPadding}

  ${({ theme }) => theme.media.phone} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`
export const StaffWrapper = styled.div`
  ${({ theme }) => theme.media.phone} {
    grid-column: 1 / 4;
    display: grid;
    margin-bottom: 0;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
  }
`
export const StaffTag = styled.div`
  background: ${({ theme }) => theme.colors.primaryL};
  color: ${({ theme }) => theme.colors.primary};
  padding: 5px 8px;
  border-radius: 8px;
  width: fit-content;
  margin-right: 10px;
  ${({ theme }) => theme.media.phone} {
    margin: 0 auto;
    text-align: center;
  }
`

export const FooterButton = styled(Button)`
  width: 120px;
  & + & {
    margin-left: 23px;
  }

  ${({ theme }) => theme.media.phone} {
    width: unset;
    flex: 1;
    & + & {
      margin-left: 0;
    }
  }
`

export const Content = styled.div`
  /* background: ${({ theme }) => theme.colors.primary}; */
  flex: 1;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${contentPadding}
`

export const EditorWrapper = styled.div`
  /* height: calc(100vh - 72px - 73px); */
  flex: 1;
  position: relative;
  .tox-tinymce {
    border: 0;
  }
`
export const FullDiv = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const TitleInput = styled(Input)`
  .MuiInput-root {
    height: 100px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }
  .MuiInputBase-input {
    ${({ theme }) => theme.font.bold}
    color: ${({ theme }) => theme.colors.black};
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.5px;
  }
  ${({ theme }) => theme.media.phone} {
    height: 50px;
    .MuiInputBase-input {
      font-size: 24px;
      line-height: 32px;
      padding: 10px;
    }
  }
`
