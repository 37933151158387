import * as R from 'ramda'
import api from 'shared/api'
import FormData from 'form-data'

// export const loadArticleDraft = async ({ accessToken, articleId }) => {
//   const { data } = await api.get(`/drafts/${articleId}`, {
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//     },
//   })

//   const links = R.filter(R.propEq('type', 'Link'), data?.references)
//   const documents = R.filter(R.propEq('type', 'File'), data?.references)

//   return { ...data, links, documents }
// }

export const loadTags = async ({ accessToken, name }) => {
  if (!name) {
    return { list: [] }
  }
  const { data: list } = await api.get(`/tags?name=${name}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return { list }
}

export const uploadArticleImage = async ({ file, accessToken }) => {
  const { data: postData } = await api.post('/upload/image', null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })

  const formData = new FormData()

  formData.append('file', file)

  const { data: uploadData } = await api.post(postData?.postUrl, formData)
  const { variants } = uploadData?.result

  const publicVariant = R.find(R.includes('/public'), variants)

  return publicVariant || R.head(variants)
}

const uploadImage = async ({ imageSrc, accessToken, isNew }) => {
  if (R.is(String, imageSrc)) {
    return isNew ? '94afe19e-0719-4b11-0126-28d0a625bb00' : null
  }

  try {
    const { data: postData } = await api.post('/upload/image', null, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })

    const formData = new FormData()

    formData.append('file', imageSrc)

    await api.post(postData?.postUrl, formData)

    return postData?.id
  } catch (error) {
    throw new Error('We could not upload your image. Please try again later.')
  }
}

export const uploadFiles = async ({ list, accessToken }) => {
  const uploadRequests = list.map(async doc => {
    const { data: postData } = await api.post(
      '/upload/file',
      {
        extension: 'pdf',
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    await api.put(postData?.url, doc)
    return {
      type: 'File',
      title: doc?.path,
      value: postData?.fileName,
      isNew: true,
    }
  })

  return await Promise.all(uploadRequests)
}

const saveDraft = async ({ id, values, imageSrc, accessToken, isNew }) => {
  const image =
    imageSrc && (await uploadImage({ imageSrc, accessToken, isNew }))

  // eslint-disable-next-line no-unused-vars
  const {
    tags: _tags = [],
    links = [],
    documents = [],
    // raw,
    group,
    ...rest
  } = values

  const tags = _tags && _tags.map(t => t?.id)
  const references = R.isNil(links) ? null : [...links, ...documents]
  const groupValue = group && group !== 'followers' ? group : null

  const body = {
    ...rest,
    group: groupValue,
    tags,
    references,
    image,
  }

  await api.put(`/drafts/${id}`, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const saveArticle = async ({
  accessToken,
  values,
  articleId,
  isNew,
  asStaff,
}) => {
  const imageSrc = R.head(values?.image || [])

  if (isNew) {
    const {
      data: { id },
    } = await api.post(
      '/drafts',
      {
        createAsStaff: asStaff,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )

    await saveDraft({ id, values, imageSrc, accessToken, isNew })

    return { id }
  }

  await saveDraft({ id: articleId, values, imageSrc, accessToken })

  return { id: articleId }
}

const publishInfo = [
  'howResponsiveArePatientsToGetCare',
  'howSatisfiedAreWithTheirCare',
  'marketPotential',
  'marketGrowthCurrentSize',
  'marketGrowthProjectedGrowth',
  'noveltyOfMechanismOfAction',
  'differentiationToTheCurrentStandardOfCare',
  'possibilityOfTechnicalAndRegulatorySuccess',
  'therapeuticArea',
  'competition',
  'diseaseAreaBuzz',
  'financialPlan',
  'leadership',
  'exitFeasibility',
]

export const publishArticle = async props => {
  const { accessToken, values } = props

  const { id } = await saveArticle(props)

  const publishInfos = R.pick(publishInfo, props.values)
  const possibilityOfTechnicalAndRegulatorySuccess =
    values?.possibilityOfTechnicalAndRegulatorySuccess
      ? values?.possibilityOfTechnicalAndRegulatorySuccess === 'Yes'
      : null

  const marketGrowthCurrentSize = !R.isNil(values?.marketGrowthCurrentSize)
    ? Number(values?.marketGrowthCurrentSize)
    : null
  const marketGrowthProjectedGrowth = !R.isNil(
    values?.marketGrowthProjectedGrowth
  )
    ? Number(values?.marketGrowthProjectedGrowth)
    : null

  const publishBody = {
    ...publishInfos,
    possibilityOfTechnicalAndRegulatorySuccess,
    marketGrowthCurrentSize,
    marketGrowthProjectedGrowth,
  }

  const { data } = await api.put(`/articles/${id}/publish`, publishBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return { ...data }
}
