import * as R from 'ramda'
import { useRouter } from 'next/router'
import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useSession } from 'context/Session'
import { Loading } from 'components/elements'

import { loadComments, publishComment } from '../modules/datasource'

import * as C from './Comments.styles'

const defaultProps = {}
const propTypes = {}

export const Comments = ({
  reference,
  count: defaultCount,
  showComments,
  setShowComments,
  initialState,
}) => {
  const [count, setCount] = useState(defaultCount)
  const [anyFetching, setAnyFetching] = useState(false)
  const [crrPage, setCrrPage] = useState(1)
  const { accessToken } = useSession()
  const [crrListState, setListState] = useState(initialState || {})
  const router = useRouter()

  const { asPath } = router

  useEffect(() => {
    if (asPath.includes('#comments')) {
      setShowComments(true)
      router.push('#comments')
    }
  }, [asPath])

  const { isFetching, isLoading } = useQuery(
    ['article-comments', { accessToken, reference, showComments, crrPage }],
    () =>
      showComments && crrPage !== 1
        ? loadComments({ reference, page: crrPage, accessToken })
        : initialState || { list: [], total: 0 },
    {
      fetchPolicy: 'no-cache',
      onSuccess: data =>
        setListState(({ list = [] }) => ({
          ...data,
          list: R.uniqBy(R.prop('id'), R.concat(list, data?.list)),
        })),
    }
  )

  const { list = [], total } = crrListState

  if (isLoading && R.isEmpty(list)) {
    return (
      <C.Container>
        <Loading color="black" style={{ margin: '0 auto' }} />
      </C.Container>
    )
  }

  const doComment = ({ text, parentId }) => {
    return publishComment({
      reference,
      text,
      accessToken,
      parentId,
    }).then(({ data }) => {
      setAnyFetching(false)
      !parentId &&
        setListState(({ list = [], ...crrState }) => ({
          ...crrState,
          list: R.concat([data], list),
        }))
      setCount(c => c + 1)
      return { data }
    })
  }

  const config = {
    reference,
    anyFetching,
    setAnyFetching,
    setListState,
    setCount,
    doComment,
  }

  return !showComments ? (
    <C.CommentPlaceHolder
      showComments={() => setShowComments(true)}
      count={count}
    />
  ) : (
    <C.Container>
      <C.Header>
        <C.Title>{`Comments (${count || 0})`}</C.Title>
        <C.PublishOption onPublish={doComment} {...config} />
      </C.Header>
      <C.CommentList {...config} {...crrListState} />
      {!isFetching && list?.length < total && (
        <C.ShowMore onClick={() => setCrrPage(p => p + 1)}>
          Show more
        </C.ShowMore>
      )}
      {isFetching && <Loading color="black" style={{ margin: '0 auto' }} />}
    </C.Container>
  )
}

Comments.defaultProps = defaultProps
Comments.propTypes = propTypes

export default Comments
