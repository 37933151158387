import * as R from 'ramda'
import React from 'react'
import Head from 'next/head'

const defaultProps = {}
const propTypes = {}

const host = process.env.NEXT_PUBLIC_BASE_URL

export const ArticleSchema = ({ asDraft, data }) => {
  const { profile } = data
  const schema = `
  {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${host}/a/${data?.slug}"
    },
    "headline": "${data?.title?.replace(/[^\w\s]/gi, '')}",
    ${data?.image ? `"image": "${data?.image}",` : ''}
    "datePublished": "${data?.publishedAt}",
    "dateModified": "${data?.publishedAt}",
    "author": {
      "@type": "Person",
      "name": "${profile?.firstName} ${profile?.lastName || ''}",
      "url": "${host}/p/${profile?.username}",
      "image": {
        "@type": "ImageObject"
        ${profile?.profileImage ? `,"url": "${profile?.profileImage}"` : ''}
      }
    },
    "publisher": {
      "@type": "Organization",
      "@id": "${host}/#organization",
      "name": "TrialSite News",
      "url": "${host}",
      "sameAs": [
        "https://www.linkedin.com/company/trialsite-news/",
        "https://www.youtube.com/channel/UC4tNlDJkV_YdwMErMRutt4A",
        "https://twitter.com/TrialsiteN"
      ]
    },
    "description": "A most wonderful article",
    "keywords": [${R.map(x => (x?.name ? `"${x.name}"` : ''), data?.tags)}],
    "isAccessibleForFree": "False",
    "hasPart":
      {
      "@type": "WebPageElement",
      "isAccessibleForFree": "False",
      "cssSelector" : ".paywall"
      }
  }
`

  return (
    <Head>
      <title>
        {asDraft ? 'Draft - ' : ''}
        {data?.title}
      </title>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      {!asDraft && <script type="application/ld+json">{schema}</script>}
    </Head>
  )
}

ArticleSchema.defaultProps = defaultProps
ArticleSchema.propTypes = propTypes

export default ArticleSchema
