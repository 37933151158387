import * as R from 'ramda'
import * as yup from 'yup'
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useRouter } from 'next/router'
import { useFormikContext } from 'formik'
import { useQuery } from 'react-query'
import Autocomplete from '@mui/material/Autocomplete'
import { useSnackbar } from 'notistack'
import { useSession } from 'context/Session'
import { Form } from 'components/collections'
import { Loading } from 'components/elements'
import { useDebounce } from 'components/helpers/utils'

import {
  saveArticle,
  publishArticle,
  uploadFiles,
  loadTags,
} from '../modules/datasource'

import * as S from './SaveForm.styles'

const MIN_STEP = 0
const MAX_STEP = 1

const stripTags = (original = '') => {
  return original.replace(/(<([^>]+)>)/gi, '')
}

const generateEllipsis = (text = '', maxSize) => {
  if (R.length(text) > maxSize) {
    return R.trim(R.take(maxSize, text)) + '...'
  }
  return text
}

const defaultProps = {}
const propTypes = {}

const Tags = ({ isFetching, accessToken }) => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const { values, setFieldValue } = useFormikContext()
  const { tags = [] } = values

  // const tagsList = useTags(accessToken, inputValue)

  const debouncedSearchTerm = useDebounce(inputValue, 500)

  const { data: tagsData = {}, isLoading } = useQuery(
    ['tags', { accessToken, debouncedSearchTerm }],
    () => loadTags({ accessToken, name: debouncedSearchTerm }),
    {
      fetchPolicy: 'no-cache',
    }
  )

  const { list: tagsList = [] } = tagsData

  return (
    <S.TagsSection>
      <S.TagsTitle>
        Add up to 5 tags to help reader know what it’s about
      </S.TagsTitle>
      <S.TagsContent>
        <Autocomplete
          size="small"
          disabled={isFetching || R.length(tags) >= 5}
          multiple
          open={open}
          loading={isLoading}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          disableClearable
          renderTags={() => null}
          value={tags}
          isOptionEqualToValue={(option, value) => {
            return option?.id === value?.id
          }}
          onChange={(_, value) => {
            setFieldValue('tags', value)
          }}
          options={tagsList}
          noOptionsText="Type to search"
          renderInput={params => (
            <S.TagInput
              {...params}
              label="Tag"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <Loading size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue)
          }}
          getOptionLabel={o => o?.name}
        />
        {!R.isEmpty(tags) && (
          <S.TagList>
            {tags.map(item => (
              <S.TagItem
                disabled={isFetching}
                key={`tag-${item?.name}`}
                label={item?.name}
                onRemove={() => setFieldValue('tags', R.without([item], tags))}
              />
            ))}
          </S.TagList>
        )}
      </S.TagsContent>
    </S.TagsSection>
  )
}

const getFinalUrl = async str => {
  const checkStr = R.includes('https://', str) ? str : 'https://' + str
  const schema = yup.string().url()
  const valid = await schema.isValid(checkStr)
  return valid ? checkStr : null
}

const Links = ({ isFetching }) => {
  const [error, setError] = useState(null)
  const [key, setKey] = useState(0)
  const [title, setTitle] = useState('')
  const [linkRef, setLinkRef] = useState('')
  const { values, setFieldValue } = useFormikContext()
  const { links = [] } = values

  const addReferenceLink = async () => {
    const finalUrl = await getFinalUrl(linkRef)
    if (finalUrl) {
      const currentLinks = R.pluck('value', links)
      if (R.includes(finalUrl, currentLinks)) {
        setError('You already added this link')
        return
      }
      setFieldValue('links', [
        ...links,
        {
          type: 'Link',
          title: title,
          value: finalUrl,
        },
      ])
      setTitle('')
      setLinkRef('')
      setKey(R.add(1))
    } else {
      setError('Must be a valid url')
    }
  }

  return (
    <S.TagsSection>
      <S.TagsTitle>Create article reference link</S.TagsTitle>
      <S.ReferencesContent>
        <S.ReferencesTitleInput
          key={`refTItle-${key}`}
          onChange={event => {
            setTitle(event.target.value)
          }}
          value={title}
          label="Reference title"
          size="small"
        />
        <S.ReferenceLinkInput
          key={`refLink-${key}`}
          onChange={event => {
            setError(null)
            setLinkRef(event.target.value)
          }}
          value={linkRef}
          fullWidth
          error={error}
          helperText={error}
          label="Paste or type a link"
          size="small"
        />
        <S.ReferenceAddButton
          onClick={addReferenceLink}
          type="button"
          variant="text"
          disabled={isFetching || R.isEmpty(linkRef)}
        >
          Add
        </S.ReferenceAddButton>

        {!R.isEmpty(links) && (
          <S.RefList>
            {links.map(item => (
              <S.TagItem
                disabled={isFetching}
                key={`reftag-${item?.value}`}
                label={
                  <S.TagLabel>
                    <S.LinkIcon />
                    {item?.title || item?.value}
                  </S.TagLabel>
                }
                onRemove={() =>
                  setFieldValue('links', R.without([item], links))
                }
              />
            ))}
          </S.RefList>
        )}
      </S.ReferencesContent>
    </S.TagsSection>
  )
}

const Documents = ({
  isFetching,
  accessToken,
  isUploading,
  setIsUploading,
  formData,
}) => {
  const [files, setFiles] = useState(formData?.documents || [])
  const { setFieldValue } = useFormikContext()
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxFiles: 1,
    disabled: isFetching || isUploading,
    accept: '.pdf',
    onDrop: async acceptedFiles => {
      setIsUploading(true)
      const uploadedFiles = await uploadFiles({
        list: acceptedFiles,
        accessToken,
      })
      setFiles(x => [...x, ...uploadedFiles])
      setIsUploading(false)
    },
  })

  useEffect(() => {
    setFieldValue('documents', files)
  }, [files])

  return (
    <S.DocumentsSection>
      <S.DocumentsContent>
        <input {...getInputProps()} style={{ display: 'none' }} />
        <S.DocumentsAddButton
          type="button"
          variant="text"
          disabled={isFetching || isUploading}
          loading={isUploading}
          {...getRootProps({ className: 'dropzone' })}
        >
          Attach a PDF document
        </S.DocumentsAddButton>
        {!R.isEmpty(files) && (
          <S.DocumentsList>
            {files.map((file, index) => (
              <S.TagItem
                disabled={isFetching}
                key={file.title}
                label={
                  <S.TagLabel>
                    <S.PdfIcon />
                    {file.title}
                  </S.TagLabel>
                }
                onRemove={() => setFiles(R.remove(index, 1))}
              />
            ))}
          </S.DocumentsList>
        )}
      </S.DocumentsContent>
    </S.DocumentsSection>
  )
}

const Actions = ({
  articleId,
  formData,
  isNew,
  setEditDisabled,
  close,
  isUploading,
  isFetching,
  setIsFetching,
  accessToken,
  asStaff,
  crrStep,
  nextStep,
}) => {
  const [buttonClicked, setClicked] = useState(null)
  const router = useRouter()
  const { values } = useFormikContext()
  const { enqueueSnackbar } = useSnackbar()

  const save = async () => {
    setEditDisabled(true)
    setIsFetching(true)
    setClicked('save')
    try {
      const { id } = await saveArticle({
        accessToken,
        values: {
          ...formData,
          ...values,
        },
        articleId,
        isNew,
        asStaff,
      })
      router.push(`/drafts/${id}`)
      close()
      setEditDisabled(false)
      enqueueSnackbar('Draft saved', { variant: 'success' })
    } catch (e) {
      const message =
        e?.response?.data?.message ||
        e?.response?.data ||
        e?.message ||
        'Something went wrong'
      if (R.is(Array, message)) {
        enqueueSnackbar(message[0], { variant: 'error' })
      } else {
        enqueueSnackbar(message, { variant: 'error' })
      }
      setEditDisabled(false)
      setIsFetching(false)
      setClicked(null)
    }
  }

  const publish = async () => {
    if (R.isEmpty(values?.image || [])) {
      enqueueSnackbar('You must have a picture to publish', {
        variant: 'error',
      })
      return
    }
    if (R.isEmpty(values?.tags || [])) {
      enqueueSnackbar('You must add at least one tag', {
        variant: 'error',
      })
      return
    }

    if (crrStep === 0) {
      nextStep()
      return
    }

    setEditDisabled(true)
    setIsFetching(true)
    setClicked('publish')
    try {
      const { slug } = await publishArticle({
        accessToken,
        values: {
          ...formData,
          ...values,
        },
        articleId,
        isNew,
        asStaff,
      })

      router.push(`/a/${slug}`)
      close()
      setEditDisabled(false)
      enqueueSnackbar(
        'Your article is pending review and should be published soon.',
        { variant: 'success' }
      )
    } catch (e) {
      setEditDisabled(false)
      setIsFetching(false)
      const message =
        e?.response?.data?.message ||
        e?.response?.data ||
        e?.message ||
        'Something went wrong'
      if (R.is(Array, message)) {
        enqueueSnackbar(message[0], { variant: 'error' })
      } else {
        enqueueSnackbar(message, { variant: 'error' })
      }
    }
  }

  return (
    <S.ActionsSection>
      <S.StepButton
        onClick={publish}
        type="button"
        disabled={isFetching || isUploading}
        loading={isFetching && buttonClicked === 'publish'}
      >
        Publish now
      </S.StepButton>
      {crrStep === 0 && (
        <S.StepButton
          onClick={save}
          type="button"
          variant="text"
          disabled={isFetching || isUploading}
          loading={isFetching && buttonClicked === 'save'}
        >
          Save as a draft
        </S.StepButton>
      )}
    </S.ActionsSection>
  )
}

export const Save = props => {
  const { user, accessToken, loadGroups } = useSession()
  const [isUploading, setIsUploading] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const { formData, asStaff } = props
  const [groupOptions, setGroupsOptions] = useState([])

  const [crrStep, setCrrStep] = useState(0)

  const nextStep = () => {
    setCrrStep(R.add(1))
    // scroll div motal-content to top
    document.querySelector('.modal-content').scrollTo(0, 0)
  }

  const prevStep = () => {
    setCrrStep(crr => R.clamp(MIN_STEP, MAX_STEP, R.subtract(crr, 1)))
  }

  useEffect(async () => {
    const response = await loadGroups()
    setGroupsOptions(
      response.map(group => ({
        value: group.id,
        label: group.name,
      }))
    )
  }, [])

  const { groupId } = props

  const config = {
    user,
    accessToken,
    isFetching,
    setIsFetching,
    isUploading,
    setIsUploading,
    crrStep,
    nextStep,
    prevStep,
    ...props,
  }

  return (
    <S.Container>
      <Form
        style={{ width: '100%' }}
        initialValues={{
          tags: [],
          group: groupId ? Number(groupId) : 'followers',
          ...formData,
          image:
            formData?.image ||
            'https://imagedelivery.net/WEWiZyGwyq-Q4qn_WluYYg/94afe19e-0719-4b11-0126-28d0a625bb00/public',

          possibilityOfTechnicalAndRegulatorySuccess:
            formData?.possibilityOfTechnicalAndRegulatorySuccess ? 'Yes' : 'No',
        }}
      >
        {crrStep === 0 && (
          <S.Content>
            <S.ArticleSection>
              <S.ImageContainer>
                <Form.Upload
                  style={{ width: '100%' }}
                  name="image"
                  renderInput={(f = []) => {
                    const previewUrl = R.head(f)

                    return (
                      <S.ImgPreview
                        src={
                          previewUrl?.preview ||
                          (!R.isEmpty(f) && f) ||
                          undefined
                        }
                      >
                        {previewUrl ? '' : 'Add a preview image'}
                      </S.ImgPreview>
                    )
                  }}
                />
                Click on the image if you wish to update
                <div></div>
              </S.ImageContainer>
              <S.ArticlePreview>
                {asStaff && <S.StaffTag>TrialSite Staff</S.StaffTag>}
                <S.TitlePreview>
                  {generateEllipsis(formData?.title, 63)}
                </S.TitlePreview>
                <S.ContentPreview>
                  {generateEllipsis(
                    stripTags(formData?.raw || formData?.content),
                    131
                  )}
                </S.ContentPreview>
              </S.ArticlePreview>
            </S.ArticleSection>
            <S.TagsSection>
              <S.TagsTitle>Publish also on:</S.TagsTitle>
              <S.DocumentsContent>
                <Form.Select
                  options={[
                    { value: 'followers', label: 'Trial Site News' },
                    ...groupOptions,
                  ]}
                  name="group"
                />
              </S.DocumentsContent>
            </S.TagsSection>
            <Tags {...config} />
            <Links {...config} />
            <Documents {...config} />
            <Actions {...config} />
          </S.Content>
        )}

        {crrStep === 1 && (
          <S.Content>
            <S.TagsSection
              style={{ borderTop: 0, marginTop: 0, paddingTop: 0 }}
            >
              <S.TagsTitle>How responsive are patients to get care</S.TagsTitle>
              <S.DocumentsContent>
                <Form.Select
                  options={['High', 'Medium', 'Low'].map(item => ({
                    value: item,
                    label: item,
                  }))}
                  name="howResponsiveArePatientsToGetCare"
                />
              </S.DocumentsContent>

              <br />

              <S.TagsTitle>How satisfied are with their care</S.TagsTitle>
              <S.DocumentsContent>
                <Form.Select
                  options={['High', 'Medium', 'Low', 'Neutral'].map(item => ({
                    value: item,
                    label: item,
                  }))}
                  name="howSatisfiedAreWithTheirCare"
                />
              </S.DocumentsContent>

              <br />

              <S.TagsTitle>Market Potential</S.TagsTitle>
              <S.DocumentsContent>
                <Form.Select
                  options={['Large', 'Medium', 'Small'].map(item => ({
                    value: item,
                    label: item,
                  }))}
                  name="marketPotential"
                />
              </S.DocumentsContent>

              <br />

              <S.TagsTitle>Market Growth</S.TagsTitle>
              <S.DocumentsContent style={{ gap: 8 }}>
                <Form.Input
                  name="marketGrowthCurrentSize"
                  helperText="Current Size"
                  type="number"
                />
                <Form.Input
                  name="marketGrowthProjectedGrowth"
                  helperText="Projected Growth (%)"
                  type="number"
                  InputProps={{ endAdornment: '%' }}
                />
              </S.DocumentsContent>

              <br />

              <S.TagsTitle>Novelty of mechanism of action</S.TagsTitle>
              <S.DocumentsContent>
                <Form.Select
                  options={['Sophisticated', 'Competitive', 'In use'].map(
                    item => ({
                      value: item,
                      label: item,
                    })
                  )}
                  name="noveltyOfMechanismOfAction"
                />
              </S.DocumentsContent>

              <br />

              <S.TagsTitle>
                Differentiation to the current standard of care
              </S.TagsTitle>
              <S.DocumentsContent>
                <Form.Select
                  options={[
                    'More Effective',
                    'Neutral',
                    'Less Effective',
                    'No Information Available',
                  ].map(item => ({
                    value: item,
                    label: item,
                  }))}
                  name="differentiationToTheCurrentStandardOfCare"
                />
              </S.DocumentsContent>

              <br />

              <S.TagsTitle>
                Possibility of technical and regulatory success
              </S.TagsTitle>
              <S.DocumentsContent>
                <Form.Radio
                  options={[
                    {
                      label: 'Yes',
                      value: 'Yes',
                    },
                    {
                      label: 'No',
                      value: 'No',
                    },
                  ]}
                  name="possibilityOfTechnicalAndRegulatorySuccess"
                />
              </S.DocumentsContent>

              <br />

              <S.TagsTitle>Therapeutic Area</S.TagsTitle>
              <S.DocumentsContent>
                <Form.Input name="therapeuticArea" />
              </S.DocumentsContent>

              <br />

              <S.TagsTitle>Competition</S.TagsTitle>
              <S.DocumentsContent>
                <Form.Select
                  options={['Wide Open', 'Opportunity', 'Saturated'].map(
                    item => ({
                      value: item,
                      label: item,
                    })
                  )}
                  name="competition"
                />
              </S.DocumentsContent>

              <br />

              <S.TagsTitle>Disease area &quot;buzz&quot;</S.TagsTitle>
              <S.DocumentsContent>
                <Form.Input name="diseaseAreaBuzz" />
              </S.DocumentsContent>

              <br />

              <S.TagsTitle>Financial plan</S.TagsTitle>
              <S.DocumentsContent>
                <Form.Select
                  options={[
                    'Funded',
                    'Not Funded',
                    'Looking For Funding',
                    'No Information Available',
                  ].map(item => ({
                    value: item,
                    label: item,
                  }))}
                  name="financialPlan"
                />
              </S.DocumentsContent>

              <br />

              <S.TagsTitle>Leadership</S.TagsTitle>
              <S.DocumentsContent>
                <Form.Select
                  options={['Solid', 'Good', 'Deficient', 'Neutral'].map(
                    item => ({
                      value: item,
                      label: item,
                    })
                  )}
                  name="leadership"
                />
              </S.DocumentsContent>

              <br />

              <S.TagsTitle>Exit feasibility</S.TagsTitle>
              <S.DocumentsContent>
                <Form.Select
                  options={['High', 'Medium', 'Low', 'Uncertain'].map(item => ({
                    value: item,
                    label: item,
                  }))}
                  name="exitFeasibility"
                  helperText="How clearly do we see the company to be acquired in less than 5-7 years"
                />
              </S.DocumentsContent>
            </S.TagsSection>

            <Actions {...config} />
          </S.Content>
        )}
      </Form>
    </S.Container>
  )
}

Save.defaultProps = defaultProps
Save.propTypes = propTypes

export default Save
