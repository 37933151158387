import { not, isNil, add, subtract, __ } from 'ramda'
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import NProgress from 'nprogress'
import { useSession } from 'context/Session'
import { Loading } from 'components/elements'
import { BiLike } from 'react-icons/bi'
import Tooltip from '@mui/material/Tooltip'

import { loadIsLiked, like, unlike } from '../modules/datasource'

const defaultProps = {
  size: 'small',
}
const propTypes = {}

const SmallStyles = css`
  ${({ theme }) => theme.font.body2}
  border-radius: 13px;
`
const MediumStyles = css`
  ${({ theme }) => theme.font.body}
  border-radius: 16px;
`

const sizeMap = {
  small: SmallStyles,
  medium: MediumStyles,
}

const Container = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  ${({ isLiked }) =>
    isLiked &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`
const LikeIcon = styled(BiLike)`
  max-height: 28px;
  border: solid 1px transparent;
  ${({ size }) => sizeMap[size]}
  transition: all 0.2s ease-in-out;
  max-height: 28px;
  max-width: 28px;
  min-height: 28px;
  min-width: 28px;
`

export const LikeButton = props => {
  const { isLiked: defaultIsLiked, data, style, ...rest } = props
  const { accessToken } = useSession()
  const [isFetching, setIsFetching] = useState(data?.id)
  const [isLiked, setIsLiked] = useState(defaultIsLiked)
  const [likeCount, setLikeCount] = useState(data?.likes)

  const articleId = data?.id

  useEffect(async () => {
    if (!isNil(defaultIsLiked) || !accessToken) {
      setIsFetching(false)
      return
    }
    if (accessToken && articleId) {
      setIsFetching(true)
      try {
        const isLiked = await loadIsLiked({
          articleId,
          accessToken,
        })
        setIsLiked(isLiked)
        setIsFetching(false)
      } catch (e) {
        setIsFetching(false)
      }
    }
  }, [])

  useEffect(() => {
    setIsLiked(defaultIsLiked)
  }, [defaultIsLiked])

  const toggleLike = async () => {
    if (accessToken) {
      NProgress.start()
      if (isLiked) {
        await unlike({ accessToken, articleId })
        setLikeCount(subtract(__, 1))
      } else {
        await like({ accessToken, articleId })
        setLikeCount(add(1))
      }
      setIsLiked(not)
      NProgress.done()
    }
  }

  if (isFetching) {
    return <Loading style={style} size={28} />
  }

  return (
    <Tooltip title={isLiked ? 'Remove like' : 'Like'} placement="top">
      <Container
        disabled={!accessToken}
        style={style}
        isLiked={isLiked}
        onClick={toggleLike}
      >
        <LikeIcon {...rest} />
        &nbsp;{likeCount}
      </Container>
    </Tooltip>
  )
}

LikeButton.defaultProps = defaultProps
LikeButton.propTypes = propTypes

export default LikeButton
